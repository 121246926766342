<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';

export default {
  page: {
    title: "Animais cadastrados",
    meta: [{ name: "description" }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Animais cadastrados",
      items: [
        {
          text: "Animais cadastrados",
          active: true
        }
      ],
      animals: [],
      loading: true,
    };
  },
  created(){
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getData()
  },
  methods:{
    getData(url){
      this.loading = true
      let config = {
        method: 'get',
        url: url ? url : `${process.env.VUE_APP_API}api/v1/admin/animal`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        }
      };

      axios(config)
      .then( (response) => {
        this.loading = false
        this.animals = response.data.data
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    paginate(value){
      let link = this.animals.links[value].url
      this.getData(link)
    },
    detalhe(uuid){
      console.log(uuid)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <b-overlay :show="loading" rounded="sm">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>#Registro</th>
                      <th>Nome</th>
                      <th>Proprietário</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(animal, index) in animals.data" :key="index">
                      <th scope="row">{{animal.registry}}</th>
                      <td>{{animal.name}}</td>
                      <td>{{animal.responsible.name}}</td>
                      <td>
                        <b-button size="sm" variant="primary" :to="`/animals/${animal.uuid}`" v-slot="{}">
                          Visualizar
                          <i class="ri-eye-fill align-middle ml-2"></i>
                        </b-button>
                      </td> 
                    </tr>
                    
                  </tbody>
                </table>
              </b-overlay>
            </div>
          </div>

          <b-pagination
            class="mt-4"
            v-model="animals.current_page"
            :total-rows="animals.total"
            :per-page="animals.per_page"
            aria-controls="my-table"
            align="center"
            @change="paginate"
          ></b-pagination>

        </div>
      </div>
    </div>
  </Layout>
</template>